import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import Image from "next/image";
import colors from "@styles/variables/iForkLiftVariables.module.scss";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";

export default function MainBanner(): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <>
            {isFormDisplayed ? (
                <div className={styles["main-banner"]}>
                    <Form
                        classes={{
                            formClassName: styles["form"],
                            stepTitleClassName: styles["step-title"],
                            stepDescriptionClassName:
                                styles["step-description"],
                            formButtonClassName: styles["form-button"],
                            formFieldClassName: styles["form-field"],
                            formStepInnerClassName: styles["steps-inner"],
                            fieldsClassName: {
                                radio: styles["radio-field"],
                            },
                            fieldsWrapperClassName: styles["fields-wrapper"],
                            tcpaClassName: styles["tcpa"],
                            labelAsTitleClassName:
                                styles["label-as-step-title"],
                        }}
                        colors={{
                            primaryColor: colors.youngNight,
                            progressBar: colors.youngNight,
                        }}
                        showProgress={true}
                        goToThankYouPage={true}
                    />

                    <div className={`${styles["image"]} hidden lg:block`}>
                        <Image
                            src="/iforklift/assets/images/forkliftOpt.jpg"
                            className={styles["background-img"]}
                            alt="point-of-sale"
                            layout="intrinsic"
                            priority
                            width={1500}
                            height={1730}
                        />
                    </div>
                    <div className={`${styles["image"]}  lg:hidden`}>
                        <Image
                            src="/iforklift/assets/images/forklift-mobileOpt.jpg"
                            className={styles["background-img"]}
                            alt="point-of-sale"
                            layout="intrinsic"
                            priority
                            width={1500}
                            height={1730}
                        />
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </>
    );
}
