import React, { ReactElement } from "react";

export default function PointOfSaleCostLogo(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="362"
            height="55"
            fill="none"
            viewBox="0 0 362 55"
        >
            <path
                fill="#232323"
                d="M73.6 52.5h-8.2V9.7h28.9v7.5H73.6v12.2h16.1v7.1H73.6v16zM102.3 51.1c-2.6-1.5-4.6-3.5-6.1-6.1-1.5-2.5-2.2-5.3-2.2-8.4 0-3.1.7-5.9 2.2-8.5 1.5-2.6 3.5-4.6 6.1-6.1 2.6-1.5 5.4-2.2 8.5-2.2 3.1 0 5.9.8 8.5 2.3 2.6 1.5 4.6 3.5 6.1 6.1 1.5 2.6 2.2 5.4 2.2 8.4s-.7 5.8-2.2 8.4c-1.5 2.6-3.5 4.6-6.1 6.1-2.6 1.5-5.4 2.2-8.6 2.2-3 .1-5.8-.7-8.4-2.2zm13.2-6.3c1.4-.8 2.5-2 3.3-3.4.8-1.4 1.2-3 1.2-4.8 0-1.8-.4-3.4-1.2-4.8-.8-1.4-1.9-2.6-3.3-3.4-1.4-.8-2.9-1.2-4.6-1.2-1.7 0-3.2.4-4.6 1.2-1.4.8-2.5 2-3.3 3.4-.8 1.4-1.2 3-1.2 4.8 0 1.8.4 3.4 1.2 4.8.8 1.4 1.9 2.6 3.3 3.4 1.4.8 2.9 1.2 4.6 1.2 1.7.1 3.2-.3 4.6-1.2zM152.7 20.6c1.3.4 2.5 1 3.7 1.9l-3.4 6.3c-1.6-1.1-3.2-1.7-4.9-1.7-1.2 0-2.3.4-3.3 1.1-1.1.7-1.9 1.7-2.6 3-.7 1.3-1 2.8-1.1 4.6v16.7h-7.8V20.6h6.7l.5 4.3c.9-1.6 2.1-2.8 3.5-3.7 1.5-.9 3.1-1.3 4.9-1.3 1.3 0 2.5.2 3.8.7zM191.7 52.5h-9.2l-10-13.5-4.8 5.1v8.4H160V6.9h7.8v27.6l12.5-13.9h9.9l-12.4 13 13.9 18.9zM203.9 52.5h-7.7V6.9h7.7v45.6zM212 13.5c-.7-.4-1.3-1-1.7-1.7-.4-.7-.6-1.5-.6-2.3 0-.8.2-1.5.6-2.3.4-.7 1-1.3 1.7-1.8.7-.4 1.6-.7 2.5-.7.9 0 1.8.2 2.5.7.7.4 1.3 1 1.7 1.8.4.7.6 1.5.6 2.3 0 .8-.2 1.5-.6 2.3-.4.7-1 1.3-1.7 1.7-.7.4-1.6.7-2.5.7-.9 0-1.8-.3-2.5-.7zm6.3 39h-7.8V20.6h7.8v31.9zM228.6 52.5V27.7h-4.2v-7.2h4.2v-3.9c0-7.3 4-10.9 12.1-10.9h2.5v6.8c-1.1 0-1.8-.1-2.1-.1-1.6 0-2.8.4-3.5 1.2-.8.8-1.1 2.1-1.1 3.7v3h6.7v7.2h-6.6v25h-8zM258.7 52.5h-7.8V27.7h-4.6v-7.2h4.5v-7.6l7.8-1.9v9.5h7v7.2h-7v24.8h.1z"
            ></path>
            <path
                fill="#FFC600"
                d="M268.9 52.6c-.7-.4-1.3-1-1.7-1.6-.4-.7-.6-1.4-.6-2.3 0-.9.2-1.6.6-2.3.4-.7 1-1.2 1.7-1.6.7-.4 1.6-.6 2.5-.6.9 0 1.7.2 2.5.6.7.4 1.3 1 1.7 1.6.4.7.6 1.4.6 2.3 0 .8-.2 1.6-.6 2.3-.4.7-1 1.2-1.7 1.6-.7.4-1.6.6-2.5.6-.9 0-1.7-.2-2.5-.6z"
            ></path>
            <path
                fill="#232323"
                d="M286.8 51.6c-1.7-1-3-2.3-3.9-4-.9-1.7-1.4-3.5-1.4-5.5s.5-3.8 1.4-5.5c1-1.7 2.3-3 3.9-4 1.7-1 3.5-1.5 5.6-1.5 1.5 0 2.9.3 4.2.8 1.3.5 2.4 1.2 3.4 2.1l-2.6 3.7c-.7-.6-1.5-1.1-2.2-1.4-.8-.3-1.6-.5-2.6-.5-1.1 0-2.1.3-3.1.8-.9.6-1.7 1.3-2.2 2.3-.6 1-.8 2.1-.8 3.2 0 1.2.3 2.2.8 3.2.6 1 1.3 1.7 2.2 2.3.9.6 2 .8 3 .8.9 0 1.8-.2 2.6-.5s1.5-.8 2.2-1.4l2.5 3.6c-1 .9-2.1 1.6-3.4 2.1-1.3.5-2.7.8-4.2.8-1.9.1-3.8-.4-5.4-1.4zM307.4 51.6c-1.7-1-3-2.3-4-4-1-1.7-1.5-3.5-1.5-5.5s.5-3.9 1.5-5.6c1-1.7 2.3-3 4-4 1.7-1 3.6-1.5 5.6-1.5 2 0 3.9.5 5.6 1.5 1.7 1 3 2.3 4 4 1 1.7 1.5 3.5 1.5 5.6 0 2-.5 3.8-1.5 5.5-1 1.7-2.3 3-4 4-1.7 1-3.6 1.5-5.6 1.5-2 0-3.9-.5-5.6-1.5zm8.7-4.1c.9-.5 1.6-1.3 2.2-2.2.5-.9.8-2 .8-3.1 0-1.2-.3-2.2-.8-3.2-.5-.9-1.3-1.7-2.2-2.2-.9-.5-1.9-.8-3-.8s-2.1.3-3 .8c-.9.5-1.6 1.3-2.2 2.2-.5.9-.8 2-.8 3.2 0 1.2.3 2.2.8 3.2.5.9 1.3 1.7 2.2 2.2.9.5 1.9.8 3 .8s2-.4 3-.9zM357.6 32c1.2.7 2.1 1.7 2.7 3 .6 1.3 1 2.9 1 4.8v12.7h-5.1v-12c0-1.5-.4-2.7-1.1-3.5-.7-.8-1.7-1.3-2.9-1.3-.8 0-1.6.2-2.3.7-.7.5-1.2 1.1-1.6 1.8-.4.7-.6 1.6-.6 2.5v11.8h-5.1v-12c0-1.5-.4-2.7-1.1-3.5-.7-.8-1.7-1.3-2.9-1.3-.8 0-1.6.2-2.3.7-.7.5-1.2 1.1-1.6 1.8-.4.8-.6 1.6-.6 2.5v11.8H329v-21h4.4l.3 2.2c.8-.9 1.8-1.5 2.8-2 1.1-.5 2.2-.7 3.4-.7 1.5 0 2.8.3 3.9.9 1.1.6 2 1.5 2.7 2.7.8-1.1 1.8-2 3-2.7 1.3-.6 2.6-1 3.9-1 1.7.1 3 .5 4.2 1.1zM31.2 32.9l2.3-1.4.3.6-.7.6 1 1.3-1.4 1.1-.4-1.9-.8.2-.3-.5zM12.7 28.5l-1.2-10.9-.8.1 1.2 10.9.8-.1z"
            ></path>
            <path
                fill="#232323"
                d="M11.9 29.7l7.9.6 1.3-2.1h-9.3l.1 1.5z"
            ></path>
            <path
                fill="#232323"
                d="M12.6 28.4l9.1.1s1.7-2 .1-2.3c-2.9-.6-7.8.3-7.8.3s.3-3.9.1-6.1c-.1-2.2-.6-4.2-1.6-4.9-.8-.6-1.3.1-1.3.4.4 3.3 1.4 12.5 1.4 12.5zM26.1 22.5c-.1.2 0 .4.2.6.2.1.5.1.6-.1l2.9-4.9c.1-.2 0-.4-.2-.6-.2-.1-.5-.1-.6.1l-2.9 4.9z"
            ></path>
            <path
                fill="#232323"
                d="M27.1 20.1c-.1.2-.1.4.1.5l6.4 3.8c.2.1.4 0 .5-.2l.3-.5c.1-.2.1-.4-.1-.5l-6.4-3.8c-.2-.1-.4 0-.5.2l-.3.5zM45.4 41.4V0h2.8v52.7h-2.8v-1.6c.9-1.4 1.4-3.1 1.4-4.9 0-1.7-.5-3.4-1.4-4.8z"
            ></path>
            <path
                fill="#232323"
                d="M43.1 30.4l3.7-.7-.3-1.4-3.7.7.3 1.4z"
            ></path>
            <path fill="#232323" d="M38.5 31.5l4.8-.9-.4-1.9-4.8.8.4 2z"></path>
            <path
                fill="#232323"
                d="M5 3.4v-.1c0-.2 0-.6.1-1.1.1-.5.3-1 .8-1.4.4-.3.9-.5 1.2-.6.4-.1.7-.1 1-.1h.2l10.4.1s.8 0 1.9.1c1 .1 2.3.2 3.2.7 3.3 1.6 4.4 3.9 4.6 4.3l19.8 37.6-1.4.7-.4.1c-.6-2.2-2.1-4.1-4-5.3l.4-2.3L27 5.9c0-.1-.9-2.2-3.8-3.5-.5-.3-1.7-.5-2.6-.5-.5 0-.9-.1-1.2-.1h-.5L8.4 1.7h-.1c-.1 0-.4 0-.6.1-.2.1-.4.1-.6.3-.1 0-.2.3-.2.5-.1.3-.1.6-.1.8v.1L5.7 31.1 4 30.9 5 3.4z"
            ></path>
            <path
                fill="#232323"
                d="M29 46.3c0 4.7 3.8 8.5 8.5 8.5S46 51 46 46.3s-3.8-8.5-8.5-8.5-8.5 3.8-8.5 8.5zm3.5 0c0-2.8 2.2-5 5-5s5 2.2 5 5-2.2 5-5 5-5-2.2-5-5z"
            ></path>
            <path
                fill="#FFC600"
                d="M37.5 44.5c1 0 1.8.8 1.8 1.8s-.8 1.8-1.8 1.8-1.8-.8-1.8-1.8.8-1.8 1.8-1.8zM38.5 43.6c-.3.2-.6.1-.8-.2-.2-.3-.1-.6.2-.8.3-.2.6-.1.8.2.2.3.1.6-.2.8zM40.1 45.1c.2-.3.5-.4.8-.2.3.2.4.5.2.8-.2.3-.5.4-.8.2-.2-.2-.3-.6-.2-.8zM39.9 48c.3 0 .6.3.6.6s-.3.6-.6.6-.6-.3-.6-.6.3-.6.6-.6zM36.4 49c.3-.2.6-.1.8.2.2.3.1.6-.2.8-.3.2-.6.1-.8-.2-.1-.3-.1-.6.2-.8zM34.6 46.7c.3.2.4.5.2.8-.2.3-.5.4-.8.2-.3-.2-.4-.5-.2-.8.2-.2.5-.3.8-.2zM34.5 44c0-.3.3-.6.6-.6s.6.3.6.6-.3.6-.6.6-.6-.3-.6-.6zM3.1 34.4c0-1.7 1.4-3.1 3.1-3.1h10.3c1.7 0 3.1 1.4 3.1 3.1v9.8c0 1.7-1.4 3.1-3.1 3.1h-2.1c0-.1 0-.2-.1-.3v-.1c0-.1 0-.1-.1-.2v-.1c0-.1 0-.1-.1-.2v-.1c0-.1-.1-.1-.1-.2V46c0-.1-.1-.2-.1-.2v-.1c0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1l-.1-.1s0-.1-.1-.1c0-.1-.1-.1-.1-.2l-.1-.1-.2-.2s0-.1-.1-.1l-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.2-.2-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1s-.1 0-.1-.1l-.1-.1s-.1 0-.1-.1c-.1-.1-.2-.1-.3-.2H11l-.1-.1h-.1s-.1 0-.1-.1h-.1c-.1 0-.1 0-.2-.1h-.1c-.1-.1-.3-.1-.4-.2-.1 0-.1 0-.2-.1-.6-.2-1.3-.3-2-.3-1.9 0-3.6.8-4.8 2.1v-9.6h.2z"
            ></path>
            <path
                fill="url(#paint0_linear_493_156)"
                d="M3.1 34.4c0-1.7 1.4-3.1 3.1-3.1h10.3c1.7 0 3.1 1.4 3.1 3.1v9.8c0 1.7-1.4 3.1-3.1 3.1h-2.1c0-.1 0-.2-.1-.3v-.1c0-.1 0-.1-.1-.2v-.1c0-.1 0-.1-.1-.2v-.1c0-.1-.1-.1-.1-.2V46c0-.1-.1-.2-.1-.2v-.1c0-.1-.1-.1-.1-.2 0 0 0-.1-.1-.1l-.1-.1s0-.1-.1-.1c0-.1-.1-.1-.1-.2l-.1-.1-.2-.2s0-.1-.1-.1l-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.2-.2-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1s-.1 0-.1-.1l-.1-.1s-.1 0-.1-.1c-.1-.1-.2-.1-.3-.2H11l-.1-.1h-.1s-.1 0-.1-.1h-.1c-.1 0-.1 0-.2-.1h-.1c-.1-.1-.3-.1-.4-.2-.1 0-.1 0-.2-.1-.6-.2-1.3-.3-2-.3-1.9 0-3.6.8-4.8 2.1v-9.6h.2z"
            ></path>
            <path
                fill="#232323"
                d="M2.2 49c0 3.2 2.6 5.8 5.8 5.8 3.2 0 5.8-2.6 5.8-5.8 0-3.2-2.6-5.8-5.8-5.8-3.2 0-5.8 2.6-5.8 5.8zm2.3 0c0-1.9 1.5-3.4 3.4-3.4s3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4c-1.8 0-3.4-1.5-3.4-3.4z"
            ></path>
            <path
                fill="#FFC600"
                d="M7.9 47.8c.7 0 1.2.6 1.2 1.2 0 .7-.6 1.2-1.2 1.2-.7 0-1.2-.6-1.2-1.2 0-.7.6-1.2 1.2-1.2zM8.7 47.2c-.2.1-.4 0-.5-.1-.1-.2 0-.4.1-.5.2-.1.4 0 .5.1.1.1 0 .3-.1.5zM9.8 48.2c.1-.2.3-.2.5-.1s.2.3.1.5-.3.2-.5.1-.2-.3-.1-.5zM9.6 50.2c.2 0 .4.2.4.4s-.2.4-.4.4-.4-.2-.4-.4c0-.3.2-.4.4-.4zM7.2 50.9c.2-.1.4 0 .5.1.1.2 0 .4-.1.5-.2.1-.4 0-.5-.1-.1-.2-.1-.4.1-.5zM6 49.3c.2.1.2.3.1.5s-.3.2-.5.1-.2-.3-.1-.5c.1-.1.3-.2.5-.1zM5.9 47.5c0-.2.2-.4.4-.4s.4.2.4.4-.2.4-.4.4c-.2-.1-.4-.2-.4-.4z"
            ></path>
            <path
                fill="#FFC600"
                d="M0 33.3v11c0 1.2.6 2.3 1.4 3 .3-1.1.9-2.1 1.7-2.9v-10c0-1.7 1.4-3.1 3.1-3.1h10.3c1.7 0 3.1 1.4 3.1 3.1v9.8c0 1.7-1.4 3.1-3.1 3.1h-2.1c0 .1 0 .2.1.2V48.2l13.7.1c-.1-.6-.2-1.3-.2-2 0-5.2 4.2-9.3 9.3-9.3 2.3 0 4.4.8 6.1 2.2v-4l-5-9.2-2.5-4.6c-1.5-.4-3.2-.9-3.6-.8-.9.1-2.6 2.7-2.3 3.6.2.8 4.4 2.8 4.4 6.7v.4c-.2 1.8-1.7 3.2-3.6 3.2h-9.1v-1.2c0-2.1-1.7-3.8-3.8-3.8H3.8c-2.1 0-3.8 1.7-3.8 3.8z"
            ></path>
            <path
                fill="#232323"
                d="M13.5 17.1c.6-2.9 1.6-4.9 2.9-5.4h.1c-.6-.6-1-1.6-.9-2.7 0-.2 0-.5.1-.7h-.4c.1-1.8 1.6-3.3 3.2-3.2 1.2.1 2.3 1 2.6 2.2.1.3.9.8 1.3 1.1h-1.3c0 .3.1.6 0 .8-.1 1.4-.9 2.5-1.9 2.9.2.1.3.2.4.4l.1.1 2.8 3.8 4.8 1.2-.1.2h1c.2.1.3.2.5.3.4.2 0 .6.5 1.1.3.3-.6 1.6-1.2 1.5-.3 0-.6-.4-1-.6-.2-.1-.3-.3-.6-.4l-.2.4s-3.8-.7-5-1.3c-.3-.2-.8-.5-1.3-.9-.2 1.8-.6 4.1-.6 4.1s4.3.7 5.6 1.4c1.5.9 5.2 7.6 5.2 7.6s3.7-.9 3.9.1c.2.7-5.1 3.1-5.1 3.1l-1.2-1.6-1.1.6s-3.5-6.1-3.9-6.5c-.7-.5-8.1.8-9.2-.5-1.1-1.6-.6-6.1 0-9.1z"
            ></path>
            <path
                fill="url(#paint1_linear_493_156)"
                d="M42.5 38.4l.4-2.9-7.5-13-2.5-.6c-.2-.1-1.3.5-1.2.7l10.8 15.8z"
                opacity="0.54"
            ></path>
            <path
                fill="#232323"
                d="M52.9 13c-.7-.4-1.3-1-1.7-1.7-.4-.7-.6-1.5-.6-2.3 0-.8.2-1.5.6-2.3.4-.7 1-1.3 1.7-1.8.7-.4 1.6-.7 2.5-.7.9 0 1.8.2 2.5.7.7.4 1.3 1 1.7 1.8.4.7.6 1.5.6 2.3 0 .8-.2 1.5-.6 2.3-.4.7-1 1.3-1.7 1.7-.7.4-1.6.7-2.5.7-.9 0-1.8-.3-2.5-.7zM59.3 47.2h-7.8V16.4h7.8v30.8zM59.3 50H47.2v2.8h12.1V50z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_493_156"
                    x1="19.608"
                    x2="3.094"
                    y1="39.258"
                    y2="39.258"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" stopOpacity="0"></stop>
                    <stop offset="1" stopColor="#FFDF00"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_493_156"
                    x1="39.175"
                    x2="30.291"
                    y1="29.206"
                    y2="34.335"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFDF00"></stop>
                    <stop
                        offset="0.004"
                        stopColor="#FFE009"
                        stopOpacity="0.993"
                    ></stop>
                    <stop
                        offset="0.027"
                        stopColor="#FFE637"
                        stopOpacity="0.952"
                    ></stop>
                    <stop
                        offset="0.054"
                        stopColor="#FFEB62"
                        stopOpacity="0.907"
                    ></stop>
                    <stop
                        offset="0.082"
                        stopColor="#FFF088"
                        stopOpacity="0.858"
                    ></stop>
                    <stop
                        offset="0.112"
                        stopColor="#FFF4A8"
                        stopOpacity="0.805"
                    ></stop>
                    <stop
                        offset="0.146"
                        stopColor="#FFF8C4"
                        stopOpacity="0.746"
                    ></stop>
                    <stop
                        offset="0.184"
                        stopColor="#FFFADA"
                        stopOpacity="0.68"
                    ></stop>
                    <stop
                        offset="0.229"
                        stopColor="#FFFCEB"
                        stopOpacity="0.603"
                    ></stop>
                    <stop
                        offset="0.283"
                        stopColor="#FFFEF6"
                        stopOpacity="0.507"
                    ></stop>
                    <stop
                        offset="0.361"
                        stopColor="#FFFFFD"
                        stopOpacity="0.373"
                    ></stop>
                    <stop
                        offset="0.575"
                        stopColor="#fff"
                        stopOpacity="0"
                    ></stop>
                </linearGradient>
            </defs>
        </svg>
    );
}
